import React, { useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import { Link } from '../../Link';
import { isFeatureEnabled } from '../../../utils/userHelpers';

const Documents = ({ user }) => {
  const spaEnabled = isFeatureEnabled(user, 'spa');
  const documentNavbar =
    user?.isSystemManager ||
    user?.isBuildingInspector ||
    (spaEnabled && (user?.isTenantManager || user?.isStrataMember));

  const href = useMemo(() => {
    if (!user) {
      return null;
    }
    if (user.new_ui_enabled) {
      return `${process.env.APP_V2_BASE_URL}/documents`;
    }
    return '/documents';
  }, [user]);

  if (documentNavbar) {
    return (
      <div className="navbar-menu-item">
        <Link href={href} classNameProp="navbar-menu-item-link" target="_self">
          <Tooltip arrow title="Documents" position="bottom" animation="fade" theme="light">
            <span className="icon icon-document-white nav-icon" />
          </Tooltip>
          <span className="icon-text">Documents</span>
        </Link>
      </div>
    );
  }

  const contractorDocumentNavbar = user?.isContractor;

  if (contractorDocumentNavbar) {
    return (
      <div className="navbar-menu-item">
        <Link href={href} classNameProp="navbar-menu-item-link" target="_self" query={{ showInvoice: true }}>
          <Tooltip arrow title="Documents" position="bottom" animation="fade" theme="light">
            <span className="icon icon-document-white nav-icon" />
          </Tooltip>
          <span className="icon-text">Documents</span>
        </Link>
      </div>
    );
  }
  return null;
};

export default Documents;
